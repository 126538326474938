import { photoUrl } from 'mk/photo/photoUrl';
import { FormControl } from 'mk2/components/forms/FormControl';
import { PhotoEntity } from 'mk2/schemas';
import React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './PhotoCropField.mscss';

interface PhotoCropInputProps {
    input: WrappedFieldInputProps;
    photo: PhotoEntity;
}

interface PhotoCropInputState {
    crop: Crop;
}

const PHOTO_PREVIEW_WIDTH = 250;

class PhotoCropInput extends React.Component<PhotoCropInputProps, PhotoCropInputState> {

    private inlineStyles = { width: PHOTO_PREVIEW_WIDTH };

    constructor(props) {
        super(props);

        const { photo } = props;

        const w = PHOTO_PREVIEW_WIDTH;  // smaller image will be upscaled
        const scale = PHOTO_PREVIEW_WIDTH / photo.width;
        const h = photo.height * scale;
        const minSize = Math.min(100 * scale, photo.height * scale, photo.width * scale);

        // initial selection is 100x100 pixels of the preview (or minSize x minSize) of the preview,
        // but not more than image height
        const selw = Math.min(Math.max(100, minSize), h);

        this.state = {
            crop: {
                unit: 'px',
                x: (w - selw) * 6 / 16,  // split remaining space with golden ratio
                y: (h - selw) * 6 / 16,
                width: selw,
                height: selw,
                aspect: 1,
            },
        };
    }

    public render() {
        const { photo } = this.props;

        const scale = PHOTO_PREVIEW_WIDTH / photo.width;
        const minSize = Math.min(100 * scale, photo.height * scale, photo.width * scale);

        return (
            <div className={styles.PhotoCropInput}>
                <ReactCrop
                    src={photoUrl(photo, 's1600x1600')}
                    crop={this.state.crop}
                    onChange={this.onCropChange}
                    onComplete={this.onComplete}
                    imageStyle={this.inlineStyles}
                    minWidth={minSize}
                    minHeight={minSize}
                />
            </div>
        );
    }

    private onCropChange = (crop: Crop) => {
        this.setState({crop});
    };

    private onComplete = (crop: Crop) => {
        const { input } = this.props;

        const hasChangedCrop =
            crop.width === 0
                ? !!input.value
                : (!input.value || input.value.length !== 3 ||
                   input.value[0] !== crop.x || input.value[1] !== crop.y || input.value[2] !== crop.width);

        if (hasChangedCrop) {
            input.onChange(crop.width === 0 ? null : [crop.x, crop.y, crop.width]);
        }
    };
}

interface PhotoCropControlPublicProps {
    label?: string;
    marginLeft?: boolean;
    bottomBorder?: boolean;
    photo: PhotoEntity;
}

type PhotoCropControlProps = PhotoCropControlPublicProps & WrappedFieldProps;

const PhotoCropControl: React.StatelessComponent<PhotoCropControlProps> = ({
    input, meta, label, marginLeft, bottomBorder,                      // WrappedFieldProps
    photo,                                                             // PhotoCropControlPublicProps
}) => (
    <FormControl
        input={input}
        meta={meta}
        label={label}
        marginLeft={marginLeft}
        bottomBorder={bottomBorder}
        minimizedLabel={true /* always minimize, otherwise label overlaps with this field *//* tslint:disable-line */}
    >
        <PhotoCropInput
            photo={photo}
            input={input}
        />
    </FormControl>
);

type PhotoCropFieldProps = PhotoCropControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
};

export const PhotoCropField: React.StatelessComponent<PhotoCropFieldProps> = (props) => (
    <Field {...props} component={PhotoCropControl} />
);
